import React from 'react';
import Helmet from 'react-helmet';
import { Global } from '@emotion/core';
import { GlobalStyles } from './GlobalStyles';
// import 'sanitize.css';
import 'styles/stream.scss';

export interface Props {
  title: string;
}

export const Base: React.FC<Props> = ({ title, children }) => (
  <>
    <Global styles={GlobalStyles} />
    <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang="en" />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
    </Helmet>
    {children}
  </>
);
