import * as React from 'react';
// import styled from '@emotion/styled';
// import { fz } from 'utils/mixins';
// import { mq } from 'utils/media-queries';
import { Base } from 'components/Base';
// import { Content } from 'components/atoms/Content';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpen,
  faMap,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
// import { Header } from 'components/organisms/Header';
// import { Footer } from 'components/organisms/Footer';
// import { Home } from 'components/organisms/Home';

// const StyledTopImage = styled.div`
//   background-size: cover;
//   background-position: center top;
//   background-repeat: no-repeat;
//   background-image: url('./top.jpg');
//   min-height: 100vh;
// `;

// const StyledMessageWrap = styled.div`
//   position: relative;
//   width: 100%;
//   height: calc(100vh - 60px);

//   ${mq('sm')} {
//     height: calc(100vh - 70px);
//   }
// `;

// const StyledMessage = styled.h1`
//   ${fz(48)}

//   position: absolute;
//   top: calc(100% - 300px);
//   left: 10%;
//   text-align: left;
//   color: var(--white);
//   font-weight: normal;

//   ${mq('sm')} {
//     ${fz(64)}

//     top: 59%;
//     left: 55%;
//   }

//   ${mq('md')} {
//     ${fz(68)}

//     top: 57%;
//     left: 60%;
//   }

//   ${mq('lg')} {
//     ${fz(72)}

//     top: 55%;
//     left: 65%;
//   }
// `;

const NavListItem: React.FC = ({ children }) => (
  <li
    css={css`
      display: list-item;
      text-align: -webkit-match-parent;
      min-width: 100px;

      :last-child {
        margin-right: 15px;
      }
    `}
  >
    <a
      css={css`
        color: #fff;
      `}
    >
      {children}
    </a>
  </li>
);

interface IconBoxProps {
  title: string;
  icon: IconDefinition;
}

const IconBox: React.FC<IconBoxProps> = ({ title, icon, children }) => (
  <div
    css={css`
      // .u-icon-block__col
      padding-top: 4rem;
      padding-bottom: 4rem;

      // position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;

      // mq
      flex: 0 0 33.33333%;
      max-width: 33.33333%;

      border-left: solid 1px #e6e7e9;
      border-botom: solid 1px #e6e7e9;
    `}
  >
    <span
      css={css`
        // .u-icon-primary
        background-color: #59287a;
        border-color: #59287a;

        // .u-icon--size--xl
        width: 5rem;
        height: 5rem;
        font-size: 2.375rem;

        // .u-icon
        position: relative;
        z-index: 1;
        line-height: 0.7;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        transition: 0.3s ease-in-out;

        // .rounded-circle
        border-radius: 50%;

        margin-bottom: 1.5rem;
      `}
    >
      <FontAwesomeIcon
        icon={icon}
        css={css`
          // .u-icon__inner
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          // .fas
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;

          color: #fff;

          :before {
            content: '\f2b6';
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
          }
        `}
      ></FontAwesomeIcon>
    </span>
    <h3
      css={css`
        // h3
        display: block;
        line-height: 1.6;
        margin-bottom: 0.5rem;
        font-weight: 500;
        margin-top: 0;

        // .h5
        font-size: 1.25rem;

        // .text-center
        text-align: center;
      `}
    >
      {title}
    </h3>
    <p
      css={css`
        display: block;
        line-height: 1.8;
        margin-top: 0;
        margin-bottom: 0;
      `}
    >
      {children}
    </p>
  </div>
);

const IndexPage: React.FC = () => (
  <Base title={'Luxceed'}>
    {/* <Header></Header> */}
    <header>
      <div
        css={css`
          background-image: url('./top.jpg');
          background-position: 50% 0px;

          min-height: 100vh;
          background-repeat: no-repeat;
          background-size: cover;
        `}
      >
        <nav
          css={css`
            display: flex;
            padding: 0.5rem 1rem;
            z-index: 1030;
            background-color: transparent;
          `}
        >
          {/* brand */}
          <div
            css={css`
              // .navbar > .container-fluid
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: space-between;

              // .container-fluid
              width: 100%;
              padding-right: 15px;
              padding-left: 15px;
              margin-right: auto;
              margin-left: auto;
            `}
          >
            <a href="/">
              <span
                css={css`
                  font-size: 28px;
                  color: #fff;
                `}
              >
                Luxceed
              </span>
            </a>
          </div>
          {/* end brand */}

          <div
            css={css`
              // .navbar-collapse
              display: flex !important;
              flex-basis: auto;
              flex-grow: 1;
              align-items: center;
              margin-top: auto;
              margin-bottom: auto;
            `}
          >
            <ul
              css={css`
                display: flex;
                flex-direction: row;
                padding-left: 0;
                margin-top: 0;
                margin-bottom: 0;
                list-style: none;
              `}
            >
              <NavListItem>注文住宅</NavListItem>
              <NavListItem>建売住宅</NavListItem>
              <NavListItem>会社概要</NavListItem>
              <NavListItem>お問い合わせ</NavListItem>
            </ul>
          </div>
        </nav>
      </div>
    </header>
    <main>
      <section
        css={css`
          // .container
          padding-top: 6.25rem;
          padding-bottom: 6.25rem;
        `}
      >
        <div
          css={css`
            // .container
            max-width: 1140px;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
          `}
        >
          <h2
            css={css`
              display: block;
              line-height: 1.6;
              font-weight: 300;
              margin-top: 0;
              margin-bottom: 0.25rem;
              font-size: 2.5rem;
              text-align: center;
            `}
          >
            会社概要
          </h2>
        </div>
      </section>
      <section
        css={css`
          // .container-fuid
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
        `}
      >
        <div
          css={css`
            // .text-center
            text-align: center;

            // .row
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
          `}
        >
          <IconBox title={'Address'} icon={faMap}>
            〒802-0077
            <br />
            北九州市小倉北区馬借3丁目6番33-402号
          </IconBox>
          <IconBox title={'TEL / FAX'} icon={faMobile}>
            093-953-8169
          </IconBox>
          <IconBox title={'Mail'} icon={faEnvelopeOpen}>
            okaue@luxceed.com
          </IconBox>
        </div>
      </section>
    </main>
    {/* <StyledTopImage>
      <StyledMessageWrap>
        <StyledMessage>
          正直価格で
          <br />
          誠実な
          <br />
          家づくり
        </StyledMessage>
      </StyledMessageWrap>
    </StyledTopImage>
    <Home></Home>
    <Footer></Footer> */}
  </Base>
);

export default IndexPage;
