import { css } from '@emotion/core';
import { rootFontSizePx } from 'utils/mixins';

export const GlobalStyles = css`
  :root {
    /* Colors palette */
    --black: #303030;
    --grey: #505050;
    --light-grey: #aaa;
    --pale-grey: #fcfcfc;
    --green: #2f893d;
    --light-green: #2fa542;
    --light-blue: #00bcd4;
    --amber: #e5e5d1;
    --pale-green: #f0f9f4;
    --pale-blue: #e9f5f6;
    --white: #fff;
    /* Base colors */
    --text-color: var(--black);
    --text-light-color: var(--grey);
    --brand-color: var(--light-green);
    --brand-dark-color: var(--green);
    --accent-color: var(--light-blue);
    --border-color: var(--light-grey);
    --background-light-color: var(--pale-green);

    /* Base gap */
  }
  html {
    font-size: ${rootFontSizePx}px;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Open Sans', 'Noto Sans JP', 'Roboto', Hiragino Sans,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo,
      Helvetica, Arial, sans-serif;
    line-height: 1.7;
    font-weight: normal;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    color: var(--text-color);
    background-color: var(--pale-grey);

    &.is-open-drawer {
      position: fixed;

      @include mq(sm) {
        position: static;
      }
    }
  }

  h1,
  h2,
  h3 {
    font-style: normal;
    font-weight: normal;
  }

  h2 {
    font-size: 3.6rem;
    font-weight: normal;
    margin-bottom: 25px;
  }

  a {
    text-decoration: none;
    color: var(--text-color);
  }

  button {
    font-family: 'Noto Sans JP', 'Roboto', Hiragino Sans,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo,
      Helvetica, Arial, sans-serif;
  }

  img {
    max-width: 100%;
  }
`;
